export const breakPoints = {
  mini: "@media(max-width: 400px)",
};

export const miniPadding = {
  [breakPoints.mini]: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
};
