import React from "react";
import styled from "react-emotion";
import colors from "../../styles/colors";
import {Fat} from "../../styles/typo";

const Container = styled("div")({
  lineHeight: 1.2,
  ":not(:last-child)": {marginBottom: "3rem"},
});

const Link = styled("a")({
  marginLeft: "0.75rem",
  display: "inline-block",
  position: "relative",
  top: "0.3rem",
  width: "1rem",
  height: "1rem",
  backgroundImage: `url(${require("./icon-link.svg")})`,
  backgroundSize: "contain",
  textIndent: "-9999px",
});

const SubInfo = styled("div")({
  fontSize: "1rem",
  marginBottom: "0.5rem",
  color: "#727272",
});

const Subline = styled("div")({
  fontSize: "1rem",
  fontWeight: "bold",
  textTransform: "uppercase",
  marginBottom: "0.2rem",
});

const Publication = ({author, year, title, link, info, published}) => (
  <Container>
    <Subline>
      <span>{author}</span>
      <b css={{color: colors.brandDeemph}}> {year}</b>
    </Subline>
    <div css={{fontSize: "1.4rem", marginBottom: "0.3rem"}}>
      {title}
      {!link ? null : <Link href={link}> {link} </Link>}
    </div>
    {(info || []).map((i, index) => (
      <SubInfo key={index}> {i} </SubInfo>
    ))}
    <Fat>{published}</Fat>
  </Container>
);

export default Publication;
