import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
import styled from "react-emotion";
import colors from "../../styles/colors";
import {Col, Row} from "../../styles/basics";
import {Fat} from "../../styles/typo";

const StyledImage = styled(Img)({
  borderRadius: "50%",
  boxShadow: "0 0.1rem 2rem -0.2rem rgba(0,0,0,0.2)",
  border: `0.2rem #fff solid`,
  width: "100%",
  maxWidth: "15rem",
  marginBottom: "2rem",
});

const query = graphql`
  {
    teamImages: allFile(
      filter: {relativePath: {glob: "pages/team/*"}, extension: {in: ["jpg", "png", "jpeg"]}}
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxHeight: 300, maxWidth: 300) {
              ...GatsbyImageSharpFluid_withWebp
              originalName
            }
          }
        }
      }
    }
  }
`;

const TeamImage = ({imagePath, name}) => {
  const {
    teamImages: {edges},
  } = useStaticQuery(query);
  const image = edges
    .map(({node}) => node.childImageSharp.fluid)
    .find((img) => img.originalName === imagePath);
  if (!image) return null;
  return (
    <StyledImage
      fluid={{
        ...image,
        sizes: `(max-width: 300px) 100vw, 200px`,
      }}
      alt={name}
      title={name}
    />
  );
};

const FatLink = styled(Fat)({
  margin: "0 1rem",
  color: colors.brandDeemph,
  textDecoration: "none",
  transitionProperty: "color",
  ":hover": {
    color: colors.brandDark,
  },
}).withComponent("a");

const LinkIcon = styled("img")({
  display: "block",
  margin: "0 auto 0.2rem",
  width: "2.5rem",
  height: "2.5rem",
});

const IconLink = ({link, icon, label}) => (
  <FatLink href={link} rel="noreferrer noopener">
    <LinkIcon src={icon} alt={label} />
    <div>{label}</div>
  </FatLink>
);

const Container = styled(Col)({
  width: "50%",
  textAlign: "center",
  alignItems: "center",
  padding: "0 1rem 4rem",
  "@media(max-width: 600px)": {
    width: "100%",
  },
});

const Name = styled("div")({
  fontSize: "1.4rem",
  color: colors.brand,
});

const Attribution = styled("div")({
  marginTop: "1rem",
  fontSize: "0.7rem",
  color: colors.brandDeemph,
});

const TeamMember = ({image, title, name, university, homepage, email, imageAttribution}) => (
  <Container>
    <TeamImage imagePath={image} name={`${title ? `${title} ` : ""}${name}`} />
    <Name>
      {!title ? null : <span>{title} </span>}
      <span>{name}</span>
    </Name>
    <Fat css={{marginBottom: "1rem"}}>{university}</Fat>
    <Row css={{margin: "0 -1rem", justifyContent: "center"}}>
      {!homepage ? null : (
        <IconLink icon={require("./icon-home.svg")} link={homepage} label="Homepage" />
      )}
      {!email ? null : (
        <IconLink icon={require("./icon-mail.svg")} link={"mailto:" + email} label="Email" />
      )}
    </Row>
    {!imageAttribution ? null : <Attribution>Foto von: {imageAttribution}</Attribution>}
  </Container>
);
export default TeamMember;
