// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-barrierefreiheit-md": () => import("./../../../src/pages/barrierefreiheit.md" /* webpackChunkName: "component---src-pages-barrierefreiheit-md" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-en-md": () => import("./../../../src/pages/en.md" /* webpackChunkName: "component---src-pages-en-md" */),
  "component---src-pages-index-md": () => import("./../../../src/pages/index.md" /* webpackChunkName: "component---src-pages-index-md" */),
  "component---src-pages-publications-md": () => import("./../../../src/pages/publications.md" /* webpackChunkName: "component---src-pages-publications-md" */),
  "component---src-pages-results-index-md": () => import("./../../../src/pages/results/index.md" /* webpackChunkName: "component---src-pages-results-index-md" */),
  "component---src-pages-team-index-md": () => import("./../../../src/pages/team/index.md" /* webpackChunkName: "component---src-pages-team-index-md" */),
  "component---src-pages-tur-md": () => import("./../../../src/pages/tur.md" /* webpackChunkName: "component---src-pages-tur-md" */)
}

