import styled from "react-emotion";
import React from "react";
import {ReadMoreContext} from "../components/ReadMore";
import colors from "./colors";
import {fonts} from "./typo";
import {breakPoints} from "./layout";

export const Row = styled("div")({display: "flex"});
export const Col = styled("div")({display: "flex", flexDirection: "column"});

const sharedStyles = ({noCustomFont}) => ({
  ...(noCustomFont && fonts.system),
  padding: "3rem 3rem",
  background: "#fff",
  boxShadow: "0 0.3rem 3rem -0.5rem rgba(0,0,0,.6)",
  color: colors.brandDark,
  fontSize: "1.2rem",
  wordBreak: "break-word",
  [breakPoints.mini]: {
    padding: "3rem 1rem",
  },
  "h1, h2, h3, h4": {
    ...(noCustomFont ? fonts.systemHeading : fonts.heading),
    margin: "0 0 0.5em",
    textAlign: "center",
    letterSpacing: "0.1em",
  },
  "h1, h2": {
    fontSize: "3rem",
  },
  h3: {
    fontSize: "1.5rem",
  },
  ".subtitle": {
    ...(noCustomFont ? fonts.systemHeading : fonts.heading),
    margin: "-0.5em 0 1em",
    textAlign: "center",
    fontSize: "1.7rem",
    letterSpacing: "0.02em",
  },
});

export const TeamBox = styled("div")(sharedStyles, {
  ":not(:last-child)": {
    marginBottom: "4rem",
  },
  "& .team-container": {
    display: "flex",
    flexWrap: "wrap",
    position: "relative",
    margin: "0 -1rem -4rem",
    justifyContent: "center",
  },
});

const StyledBox = styled("div")(sharedStyles, {
  p: {
    lineHeight: 1.6,
  },
  a: {
    fontWeight: "bold",
    color: colors.brand,
    textDecoration: "underline",
    transitionProperty: "color",
    ":hover": {
      color: colors.brandDark,
      textDecoration: "none",
    },
  },
  img: {
    maxWidth: "100%",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    borderSpacing: "0",
  },

  "td, th": {
    textAlign: "left",
    padding: "0.2rem 0.75rem",
  },

  "tr:nth-child(odd) td": {
    backgroundColor: "rgba(22,115,143,.2)",
  },

  "td.is-number, th.is-number": {
    textAlign: "right",
  },
});

const OuterBox = styled("div")({
  ":not(:last-child)": {
    marginBottom: "4rem",
  },
});

export const Box = ({flipKey, children, ...props}) => (
  <ReadMoreContext.Consumer>
    {(ctx) => (
      <OuterBox
        {...props}
        innerRef={ctx && ctx.registerNode(`outer-container-${flipKey}`, {scaleMode: "immediate"})}
      >
        <StyledBox
          innerRef={
            ctx &&
            ctx.registerNode(`container-${flipKey}`, {
              scaleMode: "non-transform",
              positionMode: "none",
            })
          }
        >
          {children}
        </StyledBox>
      </OuterBox>
    )}
  </ReadMoreContext.Consumer>
);
