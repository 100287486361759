import styled from "react-emotion";
import colors from "./colors";

export const fonts = {
  body: {
    fontFamily: "Titillium, sans-serif",
  },
  heading: {
    fontFamily: "Mathlete, serif",
    lineHeight: 1,
    fontWeight: "bold",
  },
  system: {
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  systemHeading: {
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    lineHeight: 1,
    fontWeight: "bold",
  },
};

export const Fat = styled("div")({
  fontSize: "0.9rem",
  fontWeight: "bold",
  textTransform: "uppercase",
  color: colors.brandDeemph,
});
