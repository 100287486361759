import React from "react";
import styled from "react-emotion";
import {FlipGroup, OnOff} from "react-flip-primitives";
import {fonts} from "../styles/typo";
import colors from "../styles/colors";
import {Col} from "../styles/basics";

export const ReadMoreContext = React.createContext(null);

export class ReadMoreProvider extends React.Component {
  state = {
    openKeys: [],
    open: key => this.setState(({openKeys}) => ({openKeys: [...openKeys, key]})),
    close: key => this.setState(({openKeys}) => ({openKeys: openKeys.filter(k => k !== key)})),
  };

  render() {
    return (
      <FlipGroup changeKey={this.state.openKeys.join("-")} durationMs={350}>
        {registerNode => (
          <ReadMoreContext.Provider value={{...this.state, registerNode}}>
            {this.props.children}
          </ReadMoreContext.Provider>
        )}
      </FlipGroup>
    );
  }
}

const Button = styled("button")({
  ...fonts.heading,
  fontSize: "1.5rem",
  padding: "0.8rem 1.5rem 0.7rem",
  margin: "0 auto",
  color: colors.brandDark,
  border: `1px solid ${colors.brandDark}`,
  backgroundColor: "transparent",
  transition: "background-color .25s ease-out",
  cursor: "pointer",
  ":hover": {
    backgroundColor: "rgba(22,115,143,.1)",
  },
});

const ReadMore = ({children, flipKey, label = "Mehr lesen"}) => (
  <ReadMoreContext.Consumer>
    {({openKeys, open, close}) => (
      <FlipGroup changeKey={openKeys.includes(flipKey)} durationMs={350}>
        {registerInnerNode => (
          <React.Fragment>
            <div
              ref={registerInnerNode("outer", {
                transitionProps: ["opacity"],
                scaleMode: "immediate",
              })}
            >
              <OnOff
                registerNode={registerInnerNode}
                keyAndData={openKeys.includes(flipKey) ? {key: "text", data: children} : null}
                enterPositionStyle={{height: 1}}
                enterDecorationStyle={{opacity: 0}}
                leaveStyle={{height: 1, opacity: 0}}
              >
                {(key, data, registerEnterLeave) =>
                  key && (
                    <div
                      ref={registerEnterLeave(key, {
                        transitionProps: ["opacity"],
                        scaleMode: "non-transform",
                      })}
                      style={{overflow: "hidden"}}
                    >
                      {data}
                    </div>
                  )
                }
              </OnOff>
            </div>
            <Col
              innerRef={registerInnerNode("button", {
                scaleMode: "none",
              })}
            >
              <Button onClick={() => (openKeys.includes(flipKey) ? close(flipKey) : open(flipKey))}>
                {openKeys.includes(flipKey) ? "Zuklappen" : label}
              </Button>
            </Col>
          </React.Fragment>
        )}
      </FlipGroup>
    )}
  </ReadMoreContext.Consumer>
);

export default ReadMore;
