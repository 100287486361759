module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/home/circleci/choice/node_modules/gatsby-remark-images","id":"3626c0e0-58d4-5046-b0e5-cb7731a052f2","name":"gatsby-remark-images","version":"3.3.39","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/home/circleci/choice/node_modules/gatsby-remark-line-breaks","id":"ecafe3cd-e0d5-52d1-8b90-713f7532f476","name":"gatsby-remark-line-breaks","version":"1.0.0","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"gatsbyRemarkPlugins":["gatsby-remark-line-breaks",{"resolve":"gatsby-remark-images","options":{"maxWidth":800,"withWebp":true,"linkImagesToOriginal":false}}],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"/home/circleci/choice/src/components/MdxLayout.js"},"rehypePlugins":[null]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-emotion/gatsby-browser.js'),
      options: {"plugins":[],"hoist":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
